import React, { Component } from "react";
import Typed from "react-typed";
import { Typography } from "@material-ui/core";

class Header extends Component {
  render() {
    return (
      <header id="home">
        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                Home
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#about">
                About
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#projects">
                Notable Projects
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#skills">
                Skills
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#contact">
                Contact
              </a>
            </li>
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
            <h1 className="responsive-headline">I'm Mason Achatz.</h1>
            <h3>I'm a Canberra based </h3>

            <Typography variant="h3">
              <Typed
                strings={[
                  "DevOps Engineer",
                  "Software Developer",
                  "Security Specialist",
                  "UX Designer",
                ]}
                typeSpeed={40}
                backSpeed={60}
                loop
              />
            </Typography>
          </div>
        </div>

        <p className="scrolldown">
          <a
            className="smoothscroll"
            href="#about"
            alt="Smooth transition to About Me"
          >
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
